require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
window.$ = window.jQuery = require("jquery");
window.lightbox = require("lightbox2");
require("semantic-ui-sass/semantic-ui");
require("whatwg-fetch");
import "chartkick/chart.js";
import Vue from "vue/dist/vue.esm";
Function("return this;")().Vue = Vue;

window.addIndicatorForRequired = function () {
  let required_inputs = document.querySelectorAll(".field > input:required");
  Array.prototype.forEach.call(required_inputs, function (elm) {
    elm.parentElement.classList.add("required");
  });
};

window.formatDate = function (date, format) {
  format = format.replace(/yyyy/g, date.getFullYear());
  format = format.replace(/MM/g, ("0" + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/dd/g, ("0" + date.getDate()).slice(-2));
  format = format.replace(/HH/g, ("0" + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ("0" + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ("0" + date.getSeconds()).slice(-2));
  format = format.replace(/SSS/g, ("00" + date.getMilliseconds()).slice(-3));
  return format;
};

window.formatDbDate = function (db_text_date) {
  return formatDate(new Date(db_text_date), "yyyy/MM/dd");
};

window.formatDatetime = function (datetime) {
  return formatDate(new Date(datetime), "yyyy/MM/dd HH:mm");
};

window.arrayEqual = function (a, b) {
  if (!Array.isArray(a)) return false;
  if (!Array.isArray(b)) return false;
  if (a.length != b.length) return false;
  for (var i = 0, n = a.length; i < n; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

window.setEditable = function (target_dom, overlay, to_text, callback_after) {
  target_dom = $(target_dom);
  target_dom.each(function (idx, elm) {
    let overlay_dom_orig = $(overlay[0].cloneNode(true));
    if (to_text) {
      elm.innerText = to_text(elm.dataset.value);
    } else {
      elm.innerText = elm.dataset.value;
    }
    $(elm).css("position", "relative");
    if (elm.dataset.attr) {
      var attrs = JSON.parse("{" + elm.dataset.attr.replaceAll(/\s+/g, ",").replaceAll(/(\w+)\=/g, '"$1" : ') + "}");
      overlay_dom_orig.attr(attrs);
    }
    $(elm).dblclick(function () {
      let overlay_dom = overlay_dom_orig.clone(true);
      var orig = this;
      overlay_dom.css({
        "z-index": 100,
        position: "absolute",
        left: 0,
        top: 0,
        width: $(this).css("width"),
        height: $(this).css("height"),
      });
      $(this).append(overlay_dom);
      var value_fixed = function (e) {
        var show_error_dialog = function (dom, msg) {
          var answer = confirm(`エラーが発生しました(${msg})\n編集内容をキャンセルしますか?`);
          if (answer) {
            overlay_dom.remove();
          } else {
            overlay_dom.focus();
          }
        };

        if (overlay_dom[0].validationMessage !== "") {
          // バリデーションエラー発生時
          let errorMessage = "";
          if (overlay_dom[0].validity.patternMismatch && overlay_dom[0].dataset.max_length_error_message) {
            errorMessage = overlay_dom[0].dataset.max_length_error_message + "\n\n" + "現在の文字数は" + overlay_dom[0].value.length + "文字です。";
          } else if (overlay_dom[0].validity.patternMismatch && overlay_dom[0].dataset.payee_text_error_message) {
            errorMessage = overlay_dom[0].dataset.payee_text_error_message + "\n\n" + "使用可能な文字はXXXXXです。";
          } else {
            errorMessage = overlay_dom[0].validationMessage;
          }
          alert(errorMessage);
          // overlay_dom.val("");
          setTimeout(function () {
            // 何故か直後に実行するとフォーカスが移動しない
            overlay_dom.focus();
          });
          return;
        }

        if (overlay_dom.val() === orig.dataset.value) {
          // 何も変更してない場合は通信無し
          overlay_dom.remove();
          return;
        }

        // 受注予定月がisYYYYMMフォーマットに合うかどうか確認
        if (orig.dataset.column == "order_month" || orig.dataset.column == "scheduled_order_month") {
          if (overlay_dom.val() != "") {
            if (!isYYYYMM(overlay_dom.val())) {
              alert("「2020/01」の形式でないか存在しない年月です");
              overlay_dom.val("");
              setTimeout(function () {
                // 何故か直後に実行するとフォーカスが移動しない
                overlay_dom.focus();
              });
              return;
            }
          }
        }

        // 入力する項目がURLだった場合
        if ($(elm).hasClass("url")) {
          if (overlay_dom.val().length) {
            let str = overlay_dom.val();
            let pattern = new RegExp("^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$", "i");
            if (!pattern.test(str)) {
              alert("URLの形式ではありません");
              overlay_dom.val("");
              setTimeout(function () {
                // 何故か直後に実行するとフォーカスが移動しない
                overlay_dom.focus();
              });
              return;
            }
          }
        }

        // パートナー振込先名の入力チェック
        if ((elm.dataset.column == "payee" || elm.dataset.column == "payee_memo") && overlay_dom.val() != "") {
          const validText = "アイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワンガギグゲゴザジズゼゾダヂヅデドバビブベボパピプペポヴＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺ１２３４５６７８９０（）．－ーー-−　 ";
          const checkTextArray = overlay_dom.val().split("");
          for (const t of checkTextArray) {
            if (validText.indexOf(t) == -1) {
              alert("使用できない文字が含まれています。使用可能なのは以下の全角文字列のみです。\n\nアイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワンガギグゲゴザジズゼゾダヂヅデドバビブベボパピプペポヴ\nＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺ\n１２３４５６７８９０\n（）．－- 全角スペース、半角スペース");
              overlay_dom.val("");
              setTimeout(function () {
                overlay_dom.focus();
              });
              break;
            }
          }
        }

        $.ajax({
          url: orig.dataset.url,
          type: orig.dataset.method || "POST",
          dataType: "json",
          headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
          },
          data: {
            column: orig.dataset.column,
            value: $(overlay_dom).val(),
          },
        })
          .done(function (data, status, xhr) {
            if (data.result == "success") {
              $(overlay_dom).remove();
              orig.dataset.value = $(overlay_dom).val();
              if (to_text) {
                orig.innerText = to_text($(overlay_dom).val());
              } else {
                orig.innerText = $(overlay_dom).val();
              }
              if (callback_after) {
                callback_after(orig, data.data);
              }
              orig.dispatchEvent(new Event("valueupdated"));
            } else {
              show_error_dialog(orig, data.message);
            }
          })
          .fail(function (xhr, status, error) {
            show_error_dialog(orig, error);
          });
      };
      overlay_dom.val(this.dataset.value).focus().blur(value_fixed);
      if (overlay_dom[0].type != "textarea") {
        overlay_dom.on("keypress", function (e) {
          if (e.key == "Enter") {
            e.preventDefault();
            overlay_dom.blur(); // フォーカス外したときと同様の動作をさせる
          }
        });
      }
    });
  });
};

window.setEditableText = function (dom, callback_after) {
  var editable_text = $(dom);
  setEditable(editable_text, $(`<input type="text" pattern=".{0,255}" data-max_length_error_message="入力可能な文字数を超えています。(最大255文字)"></input>`), null, callback_after);
};

window.setEditableBillingBusinessNumber = function (dom, callback_after) {
  var editable_text = $(dom);
  setEditable(editable_text, $(`<input type="text" pattern="^T[0-9]{13}$"></input>`), null, callback_after);
};

window.setEditableRequiredText = function (dom, callback_after) {
  var editable_required_text = $(dom);
  setEditable(editable_required_text, $(`<input type="text" required pattern=".{0,255}" data-max_length_error_message="入力可能な文字数を超えています。(最大255文字)"></input>`), null, callback_after);
};

window.setEditableEmail = function (dom, callback_after) {
  let editable_email = $(dom);
  setEditable(editable_email, $(`<input type="email"></input>`), null, callback_after);
};

window.setEditableRequiredEmail = function (dom, callback_after) {
  let editable_email = $(dom);
  setEditable(editable_email, $(`<input type="email" required></input>`), null, callback_after);
};

window.setEditablePassword = function (dom, callback_after) {
  var editable_text = $(dom);
  setEditable(
    editable_text,
    $(`<input type="password"></input>`),
    function (text) {
      return "*".repeat(text.length);
    },
    callback_after
  );
};

window.setEditableTextarea = function (dom, callback_after) {
  var editable_textarea = $(dom);
  setEditable(editable_textarea, $(`<textarea></textarea>`), null, callback_after);
};

window.setEditableRequiredTextarea = function (dom, callback_after) {
  var editable_textarea = $(dom);
  setEditable(editable_textarea, $(`<textarea required></textarea>`), null, callback_after);
};

window.setEditableYen = function (dom, callback_after) {
  let editable_yen = $(dom);
  let input_type = $(`<input type="number" class="tar" min=${editable_yen.data("min")} max=${editable_yen.data("max")}></input>`);
  setEditable(
    editable_yen,
    $(input_type),
    function (text) {
      if (text == "") {
        return "";
      } else {
        return "¥" + String(text).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      }
    },
    callback_after
  );
};

window.setEditableRequiredYen = function (dom, callback_after) {
  let editable_yen = $(dom);
  let input_type = $(`<input type="number" class="tar" required min=${editable_yen.data("min")} max=${editable_yen.data("max")}></input>`);
  setEditable(
    editable_yen,
    $(input_type),
    function (text) {
      if (text == "") {
        return "";
      } else {
        return "¥" + String(text).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      }
    },
    callback_after
  );
};

window.setEditableNum = function (dom, callback_after) {
  var editable_num = $(dom);
  setEditable(editable_num, $(`<input type="number"></input>`), null, callback_after);
};

window.setEditableDecimal = function (dom, callback_after) {
  var editable_decimal = $(dom);
  var step = editable_decimal.attr("data-step");
  setEditable(editable_decimal, $(`<input type="number" step="${step}"></input>`), null, callback_after);
};

window.setEditableDate = function (dom, callback_after) {
  var editable_date = $(dom);
  setEditable(
    editable_date,
    $(`<input type="date"></input>`),
    function (text) {
      if (text == "") {
        return "";
      } else {
        return formatDbDate(text);
      }
    },
    callback_after
  );
};

window.setEditableDatetime = function (dom, callback_after) {
  const editable_date = $(dom);
  let value = "";
  if (dom.value) {
    value = dom.value + ":00.000000";
  }
  setEditable(
    editable_date,
    $(`<input type="datetime-local" value="${value}">`),
    function (text) {
      if (text == "") {
        return "";
      } else {
        return formatDatetime(text);
      }
    },
    callback_after
  );
};

window.setEditableRequiredDate = function (dom, callback_after) {
  let editable_date = $(dom);
  setEditable(
    editable_date,
    $(`<input type="date" required></input>`),
    function (text) {
      if (text == "") {
        return "";
      } else {
        return formatDbDate(text);
      }
    },
    callback_after
  );
};

window.setEditableWithSelect = function (dom, callback_after) {
  // プロジェクトと制作物の区別
  let project_id = $("body").attr("data-project-id");
  let content_id = $("body").attr("data-content-id");
  let target_dom = $(dom);
  target_dom.each(function (idx, elm) {
    // optionsに変更がある場合の処理
    $(elm).removeData("options");
    let options = $(elm).data("options");
    let optgroup = $(elm).data("optgroup");
    let options_str = "";
    if (optgroup) {
      optgroup.forEach((option_group) => {
        var option_dom = "";
        option_group[1].forEach((val) => {
          option_dom += `<option value='${val[1]}'>${val[0]}</option>`;
        });
        options_str += `<optgroup label="${option_group[0]}">${option_dom}</optgroup>`;
      });
    } else {
      options.forEach((val) => {
        options_str += `<option value='${val[1]}'>${val[0]}</option>`;
      });
    }
    let overlay_dom_orig = $(`<div><select class='ui search dropdown'>${options_str}</select></div>`);
    let to_text = function (value) {
      return options.find(function (elm) {
        return elm[1] == value;
      })[0];
    };
    let link_list = "";
    // 企業の名前にリンク適用
    if (elm.dataset.column == "client_id") {
      link_list += `<a href="/clients/${elm.dataset.value}" target="_blank">${to_text(elm.dataset.value)}</a>`;
    }
    // 企業処理用
    if (link_list) {
      elm.innerHTML = "";
      elm.innerHTML = link_list;
    } else {
      if (elm.dataset.value) {
        elm.innerText = to_text(elm.dataset.value);
      }
    }
    $(elm).css({
      position: "relative",
      overflow: "visible",
    });
    $(elm).append("<i class='dropdown icon' style='float: right;'></i>");
    if (elm.dataset.attr) {
      var attrs = JSON.parse("{" + elm.dataset.attr.replaceAll(/\s+/g, ",").replaceAll(/(\w+)\=/g, '"$1" : ') + "}");
      overlay_dom_orig.attr(attrs);
    }
    $(elm).dblclick(function () {
      let overlay_dom = overlay_dom_orig.clone(true);
      var orig = this;
      overlay_dom.css({
        "z-index": 100,
        position: "absolute",
        left: 0,
        top: 0,
        width: $(this).css("width"),
        height: $(this).css("height"),
      });
      if ($(elm).hasClass("five_child") || $(elm).hasClass("five_child_child")) {
        $(this).empty();
      }
      $(this).append(overlay_dom);
      let ward = "auto";
      // 下にセレクトオプションを出力
      if ($(elm).hasClass("required_size")) {
        ward = "downward";
      }
      var input_dom = overlay_dom
        .find(".ui.dropdown")
        .dropdown({
          fullTextSearch: true,
          placeholder: "",
          direction: ward,
          onHide: function () {
            value_fixed();
            return true;
          },
        })
        .css({ width: $(this).css("width") });
      if (optgroup) {
        input_dom.has("optgroup").each(function () {
          var $menu = $("<div/>").addClass("menu");
          $(this)
            .find("select")
            .children()
            .each(function (a) {
              var isDisabled = this.disabled || false;
              var groupLabel = this.label;
              $menu.append('<div class="header' + (isDisabled ? " item disabled" : "") + '">' + groupLabel + "</div>");
              $(this)
                .children()
                .each(function () {
                  return $menu.append(`<div class="item" data-value="${this.value}" data-text="${this.label}">${this.label}</div>`);
                });
              return $menu;
            });
          return $(this).find(".menu").html($menu.html());
        });
      }
      var value_fixed = function () {
        var show_error_dialog = function (dom, msg) {
          var answer = confirm(`エラーが発生しました(${msg})\n編集内容をキャンセルしますか?`);
          if (answer) {
            overlay_dom.remove();
          } else {
            input_dom.focus();
          }
        };

        let new_value = input_dom.dropdown("get value");

        if (new_value === orig.dataset.value) {
          // 何も変更してない場合は通信無し
          overlay_dom.remove();
          return;
        }
        // 入力必須項目に適用（requiredSelec）
        if ($(elm).hasClass("requiredSelect")) {
          if (new_value === "") {
            alert("入力必須項目です");
            input_dom.focus();
            return;
          }
        }
        $.ajax({
          url: orig.dataset.url,
          type: orig.dataset.method || "POST",
          dataType: "json",
          headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
          },
          data: {
            column: orig.dataset.column,
            value: new_value,
            project_id: project_id,
            content_id: content_id,
          },
        })
          .done(function (data, status, xhr) {
            if (data.result == "success") {
              $(overlay_dom).remove();
              orig.dataset.value = new_value;
              let link_list = "";
              // 企業の名前にリンク適用
              if (orig.dataset.column == "client_id") {
                link_list += `<a href="/clients/${orig.dataset.value}" target="_blank">${to_text(orig.dataset.value)}</a>`;
              }
              // 企業処理用
              if (link_list) {
                // orig.innerHTML = '';
                // orig.innerHTML = link_list;
                $(orig).html(`${link_list}<i class='dropdown icon' style='float: right;'></i>`);
              } else {
                $(orig).html(`${to_text(new_value)}<i class='dropdown icon' style='float: right;'></i>`);
              }
              if (callback_after) {
                callback_after(orig, data.data);
              }
              orig.dispatchEvent(new Event("valueupdated"));
            } else {
              show_error_dialog(orig, data.message);
            }
          })
          .fail(function (xhr, status, error) {
            show_error_dialog(orig, error);
          });
      };
      input_dom.focus().dropdown("set selected", orig.dataset.value);
    });
  });
};

window.setEditableBoolean = function (dom, callback_after) {
  let target_dom = $(dom);
  let set_value_to_dom = function (elm, value) {
    elm.innerHTML = value ? '<i class="check icon" style="color: #02c0b0;"></i>' : "";
  };
  var show_error_dialog = function (dom, msg) {
    var answer = confirm(`エラーが発生しました(${msg})\n編集内容をキャンセルしますか?`);
    if (answer) {
      overlay_dom.remove();
    }
  };
  target_dom.each(function (idx, elm) {
    set_value_to_dom(elm, JSON.parse(elm.dataset.value));
    $(elm).dblclick(function () {
      let orig = this;
      let new_value = !JSON.parse(orig.dataset.value);
      $.ajax({
        url: orig.dataset.url,
        type: orig.dataset.method || "POST",
        dataType: "json",
        headers: {
          "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        data: {
          column: orig.dataset.column,
          value: new_value,
        },
      })
        .done(function (data, status, xhr) {
          if (data.result == "success") {
            orig.dataset.value = new_value;
            set_value_to_dom(orig, new_value);
            if (callback_after) {
              callback_after(orig, data.data);
            }
            orig.dispatchEvent(new Event("valueupdated"));
          } else {
            show_error_dialog(orig, data.message);
          }
        })
        .fail(function (xhr, status, error) {
          show_error_dialog(orig, error);
        });
    });
  });
};

window.setEditableReadingStatusBoolean = function (dom, callback_after) {
  let target_dom = $(dom);
  let set_value_to_dom = function (elm, value) {
    elm.innerHTML = value ? '<i class="check square outline icon" style="color: #02c0b0;"></i>' : '<i class="square outline icon" style="color: #5a5a5a;"></i>';
  };
  var show_error_dialog = function (dom, msg) {
    var answer = confirm(`エラーが発生しました(${msg})\n編集内容をキャンセルしますか?`);
    if (answer) {
      overlay_dom.remove();
    }
  };
  target_dom.each(function (idx, elm) {
    set_value_to_dom(elm, JSON.parse(elm.dataset.value));
    $(elm).dblclick(function () {
      let orig = this;
      let new_value = !JSON.parse(orig.dataset.value);
      $.ajax({
        url: orig.dataset.url,
        type: orig.dataset.method || "POST",
        dataType: "json",
        headers: {
          "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        data: {
          column: orig.dataset.column,
          value: new_value,
        },
      })
        .done(function (data, status, xhr) {
          if (data.result == "success") {
            orig.dataset.value = new_value;
            set_value_to_dom(orig, new_value);
            if (callback_after) {
              callback_after(orig, data.data);
            }
            orig.dispatchEvent(new Event("valueupdated"));
          } else {
            show_error_dialog(orig, data.message);
          }
        })
        .fail(function (xhr, status, error) {
          show_error_dialog(orig, error);
        });
    });
  });
};

window.SetEditableAccountingShareFlag = function (dom, callback_after) {
  let target_dom = $(dom);
  let set_value_to_dom = function (elm, value) {
    if (value) {
      elm.innerHTML = '<div class="ui mini blue label">済</div>';
    } else {
      elm.innerHTML = '<div class="ui mini gray label">未</div>';
    }
  };
  var show_error_dialog = function (dom, msg) {
    var answer = confirm(`エラーが発生しました(${msg})\n編集内容をキャンセルしますか?`);
    if (answer) {
      overlay_dom.remove();
    }
  };
  target_dom.each(function (idx, elm) {
    set_value_to_dom(elm, JSON.parse(elm.dataset.value));
    $(elm).dblclick(function () {
      let orig = this;
      let new_value = !JSON.parse(orig.dataset.value);
      $.ajax({
        url: orig.dataset.url,
        type: orig.dataset.method || "POST",
        dataType: "json",
        headers: {
          "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        data: {
          column: orig.dataset.column,
          value: new_value,
        },
      })
        .done(function (data, status, xhr) {
          if (data.result == "success") {
            orig.dataset.value = new_value;
            set_value_to_dom(orig, new_value);
            if (callback_after) {
              callback_after(orig, data.data);
            }
            orig.dispatchEvent(new Event("valueupdated"));
          } else {
            show_error_dialog(orig, data.message);
          }
        })
        .fail(function (xhr, status, error) {
          show_error_dialog(orig, error);
        });
    });
  });
};

// 0以外のNULLをチェックする
window.isEmpty = function (value) {
  if (value == "" || value == null || value == undefined || (value != null && typeof value == "object" && !Object.keys(value).length)) {
    return true;
  } else {
    return false;
  }
};

// 複数のセレクトを選ぶAJAX
window.setEditableWithMultipleSelect = function (dom, callback_after) {
  // プロジェクトと制作物の区別
  let project_id = $("body").attr("data-project-id");
  let content_id = $("body").attr("data-content-id");
  let target_dom = $(dom);
  target_dom.each(function (idx, elm) {
    let options = $(elm).data("options");
    let optgroup = $(elm).data("optgroup");
    let value_array = [];
    let elm_value = elm.dataset.value.replace(/[\[\]\"\s+]/g, "");
    if (!isEmpty(elm_value)) {
      value_array = elm_value.split(",").filter(Boolean);
    }
    let options_str = "";
    if (optgroup) {
      optgroup.forEach((option_group) => {
        var option_dom = "";
        option_group[1].forEach((val) => {
          if (value_array.map(Number).includes(val[1])) {
            option_dom += `<option value='${val[1]}' selected="true">${val[0]}</option>`;
          } else {
            option_dom += `<option value='${val[1]}'>${val[0]}</option>`;
          }
        });
        options_str += `<optgroup label="${option_group[0]}">${option_dom}</optgroup>`;
      });
    } else {
      options.forEach((val) => {
        // 既存に選択されている項目を出力
        let valType;
        valType = Number;
        if (value_array.map(valType).includes(val[1])) {
          options_str += `<option value='${val[1]}' selected="">${val[0]}</option>`;
        } else {
          options_str += `<option value='${val[1]}'>${val[0]}</option>`;
        }
      });
    }
    let overlay_dom_orig = $(`<div><select class='ui search dropdown' multiple="">${options_str}</select></div>`);
    let to_text = function (value) {
      return options.find(function (elm) {
        return elm[1] == value;
      })[0];
    };
    let value_list = [];
    let link_list = "";
    value_array.forEach((value) => {
      // タグ処理用
      if (elm.dataset.column == "tag_ids") {
        if (elm.dataset.type == "content") {
          link_list += `<a href="/tags/${value}/content_show/" class="ui tiny grey label mgb3px">${to_text(value)}</a>`;
        } else {
          link_list += `<a href="/tags/${value}/project_show/" class="ui tiny grey label mgb3px">${to_text(value)}</a>`;
        }
      } else {
        value_list.push(to_text(value));
      }
    });
    // タグ処理用
    if (elm.dataset.column == "tag_ids") {
      elm.innerHTML = "";
      elm.innerHTML = link_list;
    } else {
      elm.innerText = value_list.join("、");
    }
    $(elm).css({
      position: "relative",
      overflow: "visible",
    });
    $(elm).append("<i class='dropdown icon' style='float: right;'></i>");
    if (elm.dataset.attr) {
      var attrs = JSON.parse("{" + elm.dataset.attr.replaceAll(/\s+/g, ",").replaceAll(/(\w+)\=/g, '"$1" : ') + "}");
      overlay_dom_orig.attr(attrs);
    }
    $(elm).dblclick(function () {
      let overlay_dom = overlay_dom_orig.clone(true);
      var orig = this;
      overlay_dom.css({
        "z-index": 3,
        position: "absolute",
        left: 0,
        top: 0,
        width: $(this).css("width"),
        height: $(this).css("height"),
      });
      $(this).append(overlay_dom);
      var input_dom = overlay_dom
        .find(".ui.dropdown")
        .dropdown({
          fullTextSearch: true,
          placeholder: "",
          onHide: function () {
            value_fixed();
            return true;
          },
        })
        .css({ width: $(this).css("width") });
      if (optgroup) {
        input_dom.has("optgroup").each(function () {
          var $menu = $("<div/>").addClass("menu");
          $(this)
            .find("select")
            .children()
            .each(function (a) {
              var isDisabled = this.disabled || false;
              var groupLabel = this.label;
              $menu.append('<div class="header' + (isDisabled ? " item disabled" : "") + '">' + groupLabel + "</div>");
              $(this)
                .children()
                .each(function () {
                  var class_name = "";
                  if (this.selected == true) {
                    class_name = " active filtered";
                  } else if (isDisabled || this.disabled) {
                    class_name = " disabled";
                  }
                  return $menu.append(`<div class="item${class_name}" data-value="${this.value}" data-text="${this.label}">${this.label}</div>`);
                });
              return $menu;
            });
          return $(this).find(".menu").html($menu.html());
        });
      }
      var value_fixed = function () {
        var show_error_dialog = function (dom, msg) {
          var answer = confirm(`エラーが発生しました(${msg})\n編集内容をキャンセルしますか?`);
          if (answer) {
            overlay_dom.remove();
          } else {
            input_dom.focus();
          }
        };

        let new_value = input_dom.dropdown("get value");

        if (new_value === orig.dataset.value) {
          // 何も変更してない場合は通信無し
          overlay_dom.remove();
          return;
        }
        // 入力必須項目に適用（requiredSelec）
        if ($(elm).hasClass("requiredSelect")) {
          if (new_value === "" || new_value.length === 0) {
            alert("入力必須項目です");
            input_dom.focus();
            return;
          }
        }
        $.ajax({
          url: orig.dataset.url,
          type: orig.dataset.method || "POST",
          dataType: "json",
          headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
          },
          data: {
            column: orig.dataset.column,
            value: new_value,
            project_id: project_id,
            content_id: content_id,
          },
        })
          .done(function (data, status, xhr) {
            if (data.result == "success") {
              $(overlay_dom).remove();
              orig.dataset.value = new_value;
              let new_value_list = [];
              let new_link_list = "";
              if (new_value) {
                new_value.forEach((value) => {
                  if (orig.dataset.column == "tag_ids") {
                    if ((elm.dataset.type = "content")) {
                      new_link_list += `<a href="/tags/${value}/content_show/" class="ui mini grey label mgb3px">${to_text(value)}</a>`;
                    } else {
                      new_link_list += `<a href="/tags/${value}/project_show/" class="ui mini grey label mgb3px">${to_text(value)}</a>`;
                    }
                  } else {
                    new_value_list.push(to_text(value));
                  }
                });
              }
              if (orig.dataset.column == "tag_ids") {
                $(orig).html(`${new_link_list}<i class='dropdown icon' style='float: right;'></i>`);
              } else {
                $(orig).html(`${new_value_list.join("、")}<i class='dropdown icon' style='float: right;'></i>`);
              }
              if (callback_after) {
                callback_after(orig, data.data);
              }
              orig.dispatchEvent(new Event("valueupdated"));
            } else {
              show_error_dialog(orig, data.message);
            }
          })
          .fail(function (xhr, status, error) {
            show_error_dialog(orig, error);
          });
      };
    });
  });
};

window.setEditableWithMultipleCheckbox = function (dom, callback_after) {
  // JSON型のデータに対して使用する想定
  let target_dom = $(dom);
  target_dom.each(function (idx, elm) {
    const options = $(elm).data("options");
    const column = elm.dataset.column;
    let value = elm.dataset.value;
    value ? (elm.innerText = JSON.parse(value)) : (elm.innerText = "");
    $(elm).dblclick(function () {
      let options_str = "";
      options.forEach((val) => {
        options_str += `<label class="checkBoxStyle"><input type="checkbox" value="${val}" ${value.includes(val) ? "checked" : ""} class="${column}">${val}</label>`;
      });
      let overlay_dom = $(`<div class="ui modal"><div class="flex startStart">${options_str}</div></div>`);
      const orig = this;
      overlay_dom.css({
        padding: "20px",
      });
      $(this).append(overlay_dom);
      overlay_dom
        .modal({
          onHide: function () {
            value_fixed();
            return true;
          },
        })
        .modal("show");
      const value_fixed = function () {
        const show_error_dialog = function (dom, msg) {
          const answer = confirm(`エラーが発生しました(${msg})\n編集内容をキャンセルしますか?`);
          if (answer) {
            overlay_dom.remove();
          }
        };

        const elements = document.querySelectorAll("input." + column + ":checked");
        let new_array = [];
        elements.forEach((element) => {
          new_array.push(element.value);
        });

        if (arrayEqual(value, new_array)) {
          overlay_dom.remove();
          new_array = [];
          return;
        }

        $.ajax({
          url: orig.dataset.url,
          type: orig.dataset.method || "POST",
          dataType: "json",
          headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
          },
          data: {
            column: column,
            value: new_array,
          },
        })
          .done(function (data, status, xhr) {
            if (data.result == "success") {
              $(overlay_dom).remove();
              value = new_array;
              elm.innerText = new_array;
              if (callback_after) {
                callback_after(orig, data.data);
              }
              orig.dispatchEvent(new Event("valueupdated"));
            } else {
              show_error_dialog(orig, data.message);
            }
          })
          .fail(function (xhr, status, error) {
            show_error_dialog(orig, error);
          });
      };
    });
  });
};

window.getDateFromString = function (str) {
  let strs = str.split(/[\/\-]/);
  if (strs.length != 3) {
    return null;
  }
  return new Date(parseInt(strs[0]), parseInt(strs[1]) - 1, parseInt(strs[2]));
};

window.createMonthDropdown = function (onchange) {
  let month_selector_dom = document.createElement("div");
  month_selector_dom.className = "flex betweenCenter";

  let month_part = document.createElement("div");
  month_part.className = "ui fluid selection dropdown";

  let icon = document.createElement("i");
  icon.className = "dropdown icon";
  month_part.appendChild(icon);

  let year_part = month_part.cloneNode(true);

  let month_default_text = document.createElement("div");
  month_default_text.className = "default text";
  month_default_text.textContent = "Month";
  month_part.appendChild(month_default_text);

  let month_menu = document.createElement("div");
  month_menu.className = "menu";
  for (let month = 1; month <= 12; month++) {
    let elm = document.createElement("div");
    elm.className = "item";
    elm.dataset.value = month;
    elm.textContent = month;
    month_menu.appendChild(elm);
  }
  month_part.appendChild(month_menu);

  let year_default_text = month_default_text.cloneNode(true);
  year_default_text.textContent = "Year";
  year_part.appendChild(year_default_text);

  let year_menu = document.createElement("div");
  year_menu.className = "menu";
  let now = new Date();
  for (let year_before = 0; year_before < 10; year_before++) {
    let elm = document.createElement("div");
    let year = now.getFullYear() - year_before;
    elm.className = "item";
    elm.dataset.value = year;
    elm.textContent = year;
    year_menu.appendChild(elm);
  }
  year_part.appendChild(year_menu);

  let between = document.createElement("p");
  between.className = "wd5p mgb0 fz12px";
  between.textContent = "/";

  let month_box = document.createElement("div");
  month_box.className = "wd40p";
  let year_box = month_box.cloneNode(true);
  month_box.appendChild(month_part);
  year_box.appendChild(year_part);

  let delete_icon = document.createElement("i");
  delete_icon.className = "close icon do_delete";

  month_selector_dom.appendChild(year_box);
  month_selector_dom.appendChild(between);
  month_selector_dom.appendChild(month_box);
  month_selector_dom.appendChild(delete_icon);

  return month_selector_dom;
};

var g_monthObserver = null;

window.makeChangableToMonth = function (dom) {
  let label_dom = dom.querySelector("label");
  let btn_dom = document.createElement("div");
  btn_dom.className = "ui right floated green micro button";
  label_dom.appendChild(btn_dom);

  // 何故かSemanticUIのdropdownのonChangeが動作しないのでこれで対応
  if (g_monthObserver === null) {
    g_monthObserver = new MutationObserver(function (mutationList, observer) {
      for (const mutation of mutationList) {
        if (!mutation.target.classList.contains("text")) {
          continue;
        }
        let month_changable_dom = mutation.target.parentNode.parentNode.parentNode.parentNode;
        let date_doms = month_changable_dom.querySelectorAll("input");
        let month_doms = month_changable_dom.querySelectorAll(".ui.dropdown");
        let year_str = $(month_doms[0]).dropdown("get value");
        let month_str = $(month_doms[1]).dropdown("get value");
        let format = date_doms[0].type == "date" ? "yyyy-MM-dd" : "yyyy/MM/dd";
        if (year_str != "" && month_str != "") {
          let year = parseInt(year_str);
          let month = parseInt(month_str);
          let start_date = new Date(year_str, month - 1, 1);
          let end_date = new Date(year_str, month, 0);
          date_doms[0].value = formatDate(start_date, format);
          date_doms[1].value = formatDate(end_date, format);
        }
      }
    });
  }

  let date_doms = dom.querySelectorAll("input");
  let format = date_doms[0].type == "date" ? "yyyy-MM-dd" : "yyyy/MM/dd";
  let date_container = date_doms[0].parentNode;

  let month_selector_dom = createMonthDropdown();
  let month_doms = month_selector_dom.querySelectorAll(".ui.dropdown");
  $(month_doms[0]).dropdown();
  $(month_doms[1]).dropdown();

  g_monthObserver.observe(month_doms[0], { childList: true, subtree: true });
  g_monthObserver.observe(month_doms[1], { childList: true, subtree: true });

  let switch_dom = function (by_month) {
    if (by_month) {
      dom.appendChild(month_selector_dom);
      btn_dom.textContent = "期間で指定する";
      let date = getDateFromString(date_doms[0].value);
      if (date) {
        $(month_doms[0]).dropdown("set selected", date.getFullYear());
        $(month_doms[1]).dropdown("set selected", date.getMonth() + 1);
      }
      date_container.hidden = true;
    } else {
      date_container.hidden = false;
      if (dom.contains(month_selector_dom)) {
        dom.removeChild(month_selector_dom);
      }
      btn_dom.textContent = "月単位で指定する";
    }
  };

  let storage_name = window.location.pathname + ":" + date_doms[0].name;
  switch_dom(window.localStorage.getItem(storage_name) == "month");
  btn_dom.addEventListener("click", function (elm) {
    let by_month = !date_container.hidden;
    switch_dom(by_month);
    window.localStorage.setItem(storage_name, by_month ? "month" : "term");
  });

  let delete_btn = month_selector_dom.getElementsByClassName("do_delete")[0];
  delete_btn.addEventListener("click", function (elm) {
    $(month_doms[0]).dropdown("clear");
    $(month_doms[1]).dropdown("clear");
    date_doms[0].value = "";
    date_doms[1].value = "";
  });
};

// ID複数検索時の値整形
window.replaceMultipleIds = function (target) {
  var val = target.val();
  val = val.replace(/[,　\n]/g, " ");
  target.val(val);
};

/**
 * 文字にスペースが連続2つあるとtrueを返す。
 * @param {string} str
 */
window.double_space_check = function (str) {
  if (str?.match(/\s\s/g)?.length > 0) {
    alert("ファイル名にスペース(全角、半角問わず)が連続で2つ以上入力されているため、アップロードを行うことができません。スペースの位置を見直すか、ファイル名から全てのスペースを削除した上で再度お試しください。");
    return true;
  } else {
    return false;
  }
};

window.storyBoardBlockNoSort = function (storyboard_block_id, index) {
  $.ajax({
    url: "/storyboard_blocks/no_sort/",
    type: "POST",
    data: {
      storyboard_block_id: storyboard_block_id,
      index: index,
    },
    headers: {
      "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
    dataType: "json",
  })
    .done((data) => {
      $(".result").html(data);
    })
    .fail((response) => {
      $(".result").html(response);
      if (response.status == 401) {
        alert("ログインの有効期限が切れています。ページを読み込み直して再ログインを行ってください。");
      } else {
        alert("失敗しました。もう一度お試しください。");
      }
    });
};

window.isYYYYMMDD = function (str) {
  //年,月,日を取得する
  var y = parseInt(str.substr(0, 4));
  var sura1 = str.substr(4, 1);
  var m = parseInt(str.substr(5, 2)) - 1; //月は0～11で指定するため-1しています。
  var sura2 = str.substr(7, 1);
  var d = parseInt(str.substr(8, 2));
  var dt = new Date(y, m, d);

  //判定する
  return y == dt.getFullYear() && m == dt.getMonth() && d == dt.getDate() && sura1 == "/" && sura2 == "/";
};

window.isYYYYMM = function (str) {
  if (str.length == 7) {
    //年,月,日を取得する
    var y = parseInt(str.substr(0, 4));
    var sura = str.substr(4, 1);
    var m = parseInt(str.substr(5, 2)) - 1; //月は0～11で指定するため-1しています。
    var d = 1;
    var dt = new Date(y, m, d);

    //判定する
    return y == dt.getFullYear() && m == dt.getMonth() && sura == "/";
  } else {
    return false;
  }
};

// 数値にカンマをつける
window.separate = function (num) {
  return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
};

window.showNotification = function (msg) {
  $(".instantMessage").text(msg).fadeIn("fast");
  // setTimeout(function () {
  //   $(".instantMessage").fadeOut();
  // }, 7000);
};

window.progressBarCount = 0;

window.appnedProgressBar = function (file) {
  const progress = `<div class="ui teal progress" id="progress_${progressBarCount}"><div class="bar"><div class="progress"></div></div><div class="label">${file.name}</div><i class="icon close"></i></div>`;
  $(".indicater_parent .bottom").append(progress);
};

window.setS3Upload = function (form_elm) {
  form_elm.on("submit", function (e) {
    e.preventDefault();
    e.stopPropagation();
    const formdata = new FormData($(this).get(0));
    const action = $(this).attr("action");
    const file_elm = $(this).find("input[type='file']");
    const files = file_elm.prop("files");
    const files_count = files.length;
    const tab = $(this).attr("data-tab");
    formdata.delete(file_elm[0].name);
    let completed_file_count = 0;
    $(".indicater_parent").show();
    $(".indicater_label").show();
    for (const file of files) {
      progressBarCount++;
      const progress_id = progressBarCount;
      appnedProgressBar(file);
      formdata.set("content_type", file.type);
      s3PresignedUrlUpload(formdata, file, progress_id, action)
        .then((uploadId) => {
          formdata.set("key", uploadId.data.key);
          formdata.set("upload_id", uploadId.data.upload_id);
          formdata.set("file_name", file.name);
          setUploadedId(action, formdata)
            .then(() => {
              $(`#progress_${progress_id}`).progress("increment");
              completed_file_count++;
              if (completed_file_count == files_count) {
                let url = location.pathname;
                if (tab != null) {
                  url += `?tab=${tab}`;
                }
                window.location.replace(url);
              }
            })
            .catch((err) => {
              $(`#progress_${progress_id}`).progress("set error");
              if (typeof err == "string") {
                showNotification(err);
              } else {
                throw err;
              }
            });
        })
        .catch((err) => {
          $(`#progress_${progress_id}`).progress("set error");
          if (typeof err == "string") {
            showNotification(err);
          } else {
            throw err;
          }
        });
    }
  });
};

window.s3PresignedUrlUpload = async function (formdata, file, progress_id, action_url) {
  let get_upload_id_url = action_url + "/assign_upload_id";
  let get_presigned_url = action_url + "/assign_presigned_url";
  const uploadId = await new Promise((resolve, reject) => {
    $.ajax({
      type: "POST",
      dataType: "json",
      data: formdata,
      url: get_upload_id_url,
      contentType: false,
      processData: false,
    })
      .done(function (res, status, xhr) {
        resolve(res);
      })
      .fail(function (xhr, status, error) {
        reject(`エラーが発生しました:${error} 1`);
      })
      .always(function () {
        formdata.delete("file_name");
      });
  });
  const allSize = file.size;
  const partSize = 1024 * 1024 * 100; // 100MB
  const progress_total = Math.ceil(allSize / partSize) + 2;
  $(`#progress_${progress_id}`).progress({ total: progress_total });
  $(`#progress_${progress_id}`).progress("increment");
  var partNum = 0;
  for (let rangeStart = 0; rangeStart < allSize; rangeStart += partSize) {
    partNum++;
    var presignedUrl = await new Promise((resolve, reject) => {
      $.ajax({
        type: "POST",
        dataType: "json",
        data: {
          upload_id: uploadId.data.upload_id,
          part_number: partNum,
          key: uploadId.data.key,
        },
        url: get_presigned_url,
      })
        .done(function (res, status, xhr) {
          resolve(res.presigned_url);
        })
        .fail(function (xhr, status, error) {
          reject(`エラーが発生しました:${error} 2`);
        });
    });
    const end = Math.min(rangeStart + partSize, allSize);
    const sendData = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (event) => {
        const data = event.target.result;
        let byte = new Uint8Array(data);
        resolve(byte);
        fileReader.abort();
      };
      const blob2 = file.slice(rangeStart, end);
      fileReader.readAsArrayBuffer(blob2);
    });
    await new Promise((resolve, reject) => {
      fetch(presignedUrl, {
        method: "PUT",
        body: sendData,
      })
        .then((res) => {
          if (!res.ok) {
            reject(`エラーが発生しました:${error} 3`);
          } else {
            $(`#progress_${progress_id}`).progress("increment");
            resolve("success");
          }
        })
        .catch((error) => {
          reject(`エラーが発生しました:${error} 4`);
        });
    });
  }
  return uploadId;
};

window.setUploadedId = function (action, formdata) {
  return new Promise((resolve, reject) => {
    $.ajax({
      method: "POST",
      data: formdata,
      url: action,
      dataType: "json",
      contentType: false,
      processData: false,
    })
      .done(function (res, status, xhr) {
        if (res.result == "success") {
          resolve();
        } else {
          reject(`エラーが発生しました:${res.message} 5`);
        }
      })
      .fail(function (xhr, status, error) {
        reject(`エラーが発生しました:${error} 6`);
      });
  });
};

window.set_accident_information_select = function (dom) {
  // railsのf.selectタグでrequired: trueにしてもなぜか未選択のoptionが挿入されるので削除
  if ($(dom).find(".accident_level_select option:first-child").val() == "") {
    $(dom).find(".accident_level_select option:first-child").remove();
  }
  const selected_val = $(dom).find(".accident_level_select option:selected").val();
  if (selected_val == "a_trading_halts" || selected_val == "b_trading_caution") {
    $(dom).find(".accident_type_parent, .origin_of_accident_parent, .details_of_the_accident_parent, .correspondence_contents_parent, .cause_parent, .measures_to_prevent_recurrence_parent").addClass("required");
    $(dom).find(".accident_type_select, .origin_of_accident_select, textarea").attr("required", true);
    $(dom).find(".hide_content").show();
  } else if (selected_val == "c_problem") {
    $(dom).find(".accident_type_select, .origin_of_accident_select, textarea").attr("required", false);
    $(dom).find(".details_of_the_accident_parent textarea").attr("required", true);
    $(dom).find(".accident_type_parent, .origin_of_accident_parent, .correspondence_contents_parent, .cause_parent, .measures_to_prevent_recurrence_parent").removeClass("required");
    $(dom).find(".details_of_the_accident_parent").addClass("required");
    $(dom).find(".hide_content").show();
  } else if (selected_val == "d_no_problem") {
    $(dom).find(".hide_content").hide();
    $(dom).find("select, textarea").attr("required", false);
  }
};

window.notify_error = function (url, msg) {
  const message = msg + `\nlocation: ${window.location.href}`;
  $.ajax({
    url: url,
    type: "POST",
    data: {
      message: message,
    },
    headers: {
      "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
    dataType: "json",
  });
};

// 見積書などの会計書類関連
window.update_document_row_number = function () {
  $(".document_row").each(function (index) {
    $(this)
      .find(".number")
      .val(index + 1);
  });
};

window.callFunctions = function () {
  $.fn.transition.settings.silent = true; // Semantic UIのtransitionモジュールで余計なワーニングが出まくるので抑制

  $('a[href^="#"]').click(function () {
    var speed = 500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top;
    $("html, body").animate(
      {
        scrollTop: position,
      },
      speed,
      "swing"
    );
    return false;
  });

  // window.lightbox.option({
  //   resizeDuration: 0,
  //   fadeDuration: 0,
  //   fitImagesInViewport: false,
  // });

  //Semantic UIの設定
  $(".ui.accordion").accordion();
  $(".ui.checkbox").checkbox();
  $(".ui.dropdown").dropdown({
    fullTextSearch: true,
    placeholder: "",
  });
  $(".ui.menu .item").tab();
  $(".popup").popup({
    position: "right center",
  });

  setEditableText(".editableText");
  setEditableBillingBusinessNumber(".editableBillingBusinessNumber");
  setEditableEmail(".editableEmail");
  setEditablePassword(".editablePassword");
  setEditableTextarea(".editableTextarea");
  setEditableYen(".editableYen");
  setEditableNum(".editableNum");
  setEditableDecimal(".editableDecimal");
  setEditableDate(".editableDate");
  setEditableDate(".editableDepositDate", function (dom, data) {
    const t1_dom = dom.previousElementSibling.previousElementSibling;
    if (dom.dataset.value != "") {
      const value = "¥" + String(dom.dataset.price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      t1_dom.innerHTML = value;
    } else {
      t1_dom.innerHTML = "¥0";
    }
  });
  setEditableDatetime(".editableDatetime");
  setEditableWithMultipleCheckbox(".editableWithMultipleCheckbox");
  setEditableWithMultipleSelect(".editableWithMultipleSelect");
  setEditableRequiredText(".editableRequiredText", function (dom, data) {
    if (dom.classList.contains("negative")) {
      if (dom.dataset.value != null) {
        dom.classList.remove("negative");
      }
    }
  });
  setEditableRequiredEmail(".editableRequiredEmail", function (dom, data) {
    if (dom.classList.contains("negative")) {
      if (dom.dataset.value != null) {
        dom.classList.remove("negative");
      }
    }
  });
  setEditableRequiredTextarea(".editableRequiredTextarea", function (dom, data) {
    if (dom.classList.contains("negative")) {
      if (dom.dataset.value != null) {
        dom.classList.remove("negative");
      }
    }
  });
  setEditableRequiredYen(".editableRequiredYen", function (dom, data) {
    if (dom.classList.contains("negative")) {
      if (dom.dataset.value != null) {
        dom.classList.remove("negative");
      }
    }
  });
  setEditableRequiredDate(".editableRequiredDate", function (dom, data) {
    if (dom.classList.contains("negative")) {
      if (dom.dataset.value != null) {
        dom.classList.remove("negative");
      }
    }
  });
  setEditableWithSelect(".editableWithSelect", function (dom, data) {
    if (dom.classList.contains("requiredSelect")) {
      if (dom.classList.contains("negative")) {
        if (dom.dataset.value != null) {
          dom.classList.remove("negative");
        }
      }
    }
  });
  setEditableBoolean(".editableBoolean");
  setEditableReadingStatusBoolean(".editableReadingStatusBoolean");
  SetEditableAccountingShareFlag(".editableAccountingShareFlag");
  setEditableWithMultipleSelect(".editableWithMultipleSelect", function (dom, data) {
    if (dom.classList.contains("requiredSelect")) {
      if (dom.classList.contains("negative")) {
        if (dom.dataset.value != null) {
          dom.classList.remove("negative");
        }
      }
    }
  });
  $(".changable_to_month").each(function (idx, elm) {
    makeChangableToMonth(elm);
  });

  // optgroupの設定に対応
  $(".ui.dropdown")
    .dropdown()
    .has("optgroup")
    .each(function () {
      var $menu = $("<div/>").addClass("menu");
      $(this)
        .find("select")
        .children()
        .each(function (a) {
          var isDisabled = this.disabled || false;
          var groupLabel = this.label;
          $menu.append('<div class="header' + (isDisabled ? " item disabled" : "") + '">' + groupLabel + "</div>");
          $(this)
            .children()
            .each(function () {
              var class_name = "";
              if (this.selected == true) {
                class_name = " active filtered";
              } else if (isDisabled || this.disabled) {
                class_name = " disabled";
              }
              return $menu.append(`<div class="item${class_name}" data-value="${this.value}" data-text="${this.label}">${this.label}</div>`);
            });
          return $menu;
        });
      return $(this).find(".menu").html($menu.html());
    });

  addIndicatorForRequired();

  // railsがFormのsubmit時に勝手にsubmitボタンをdisabledするのに対処
  $("form").submit(function () {
    let form = $(this);
    setTimeout(() => {
      form.find("[type='submit']").each(function (idx, elm) {
        $(elm).removeAttr("disabled");
      });
    }, 1000);
  });

  // 半角数字のみ入力可能
  $(document).on("keyup", ".allowOnlyNumbers", function (e) {
    this.value = this.value.replace(/[^0-9]+/i, "");
  });
  $(document).on("blur", ".allowOnlyNumbers", function () {
    this.value = this.value.replace(/[^0-9]+/i, "");
  });

  //Flashメッセージを5秒後に非表示
  // if ($(".flash_message")[0]) {
  //   setTimeout(function () {
  //     $(".flash_message").fadeOut();
  //   }, 5000);
  // }

  // 必須項目が設定されているドロップダウンリストが変更されたときに発火
  $(".requireSelect").dropdown({
    fullTextSearch: true,
    onChange: function () {
      if ($(this).hasClass("shootingSelectTrigger")) {
        // 制作物ページにて撮影の有無が選択された際、「有」のときには下部にある撮影オプションのセレクトボックスを必須項目にする
        if ($(".shootingSelectTrigger").children(".text").text() == "有") {
          $(".shootingSelectTarget").each(function (i, o) {
            if ($(o).children(".text").text() == "") {
              $(o).addClass("bdcRequire");
              $(o).addClass("requireSelect");
              $(o).addClass("bdcRequire").children(".search").prop("required", true);
            }
          });
        } else if ($(".shootingSelectTrigger").children(".text").text() == "無" || $(".shootingSelectTrigger").children(".text").text() == "") {
          // 制作物ページにて撮影の有無が選択された際、「無」のときには下部にある撮影オプションのセレクトボックスの必須項目を解除
          $(".shootingSelectTarget").each(function (i, o) {
            $(o).removeClass("bdcRequire");
            $(o).removeClass("requireSelect");
            $(o).removeClass("bdcRequire").children(".search").prop("required", false);
          });
        }
        if ($(this).children(".text").text() != "") {
          $(this).removeClass("bdcRequire");
          $(this).children(".search").prop("required", false);
        } else {
          $(this).addClass("bdcRequire");
          $(this).children(".search").prop("required", true);
        }
      } else {
        // 必須項目のセレクトボックスが選択された場合に必須を解除(Semantic-ui固有のレイアウトに対応)
        if ($(this).children(".text").text() != "") {
          $(this).removeClass("bdcRequire");
          $(this).children(".search").prop("required", false);
        } else {
          $(this).addClass("bdcRequire");
          $(this).children(".search").prop("required", true);
        }
      }
    },
  });
  // 必須項目を入力した場合、必須項目クラスを除去
  $(".requireText").on("change", function () {
    if ($(this).val() != "") {
      $(this).removeClass("bdcRequire");
    } else {
      $(this).addClass("bdcRequire");
    }
  });
  // フォームページを開いたときにテキスト必須項目が入力済みであれば必須項目クラスを除去(テキストボックス)
  $(".requireText").each(function (i, o) {
    if ($(o).val() != "") {
      $(o).removeClass("bdcRequire");
    }
  });
  // フォームページを開いたときにテキスト必須項目が入力済みであれば必須項目クラスを除去(セレクトボックス)
  $(".requireSelect select").each(function (i, o) {
    $(o)
      .children()
      .each(function (n, c) {
        if ($(c).val() != "") {
          if ($(c).prop("selected")) {
            $(o).parent(".requireSelect").removeClass("bdcRequire");
          }
        }
      });
  });
  // 制作物ページを開いたとき撮影の有無が「有」ならば下部にある撮影オプションを必須項目に変更する
  if ($(".shootingSelectTrigger").children(".text").text() == "無" || $(".shootingSelectTrigger").children(".text").text() == "") {
    $(".shootingSelectTarget").each(function (i, o) {
      $(o).removeClass("bdcRequire");
      $(o).removeClass("requireSelect");
      $(o).removeClass("bdcRequire").children(".search").prop("required", false);
    });
  }
  // ページを開いた必須項目のセレクトボックスにrequiredを設定(Semantic-ui固有のレイアウトに対応)
  $(".requireSelect .text").each(function (i, o) {
    if ($(o).text() == "") {
      $(o).prevAll(".search").prop("required", true);
    }
  });
  // ファイル入力のデザイン変更
  $(".compact_file_input").each(function (idx, elm) {
    // display:noneにするとrequiredのエラーメッセージがconsoleにしか出力しないのでdisplay:noneを使わない
    elm.style = "width: 0px; height: 0px !important; padding:0px; opacity: 0; float: left;";
    let label_elm = document.createElement("label");
    label_elm.classList = "compact_file_input_label";
    let file_name_elm = document.createElement("p");
    let file_choose_btn = document.createElement("div");
    file_choose_btn.textContent = "参照...";
    file_name_elm.textContent = "ファイルが選択されていません";
    elm.parentNode.append(label_elm);
    label_elm.append(elm);
    label_elm.append(file_choose_btn);
    label_elm.append(file_name_elm);
    elm.addEventListener("change", function (e) {
      if (double_space_check(elm.files[0]?.name)) {
        elm.value = "";
        file_name_elm.textContent = "ファイルが選択されていません";
        return;
      } else {
        file_name_elm.textContent = elm.files[0]?.name ?? "ファイルが選択されていません";
      }
    });
  });
  setS3Upload($(".s3-upload-form"));

  $(".global_navi .header.active").toggleClass("open");
  $(".global_navi .header.active").next().show();

  $(document).on("click", ".estimate_update_status_btn", function () {
    const modal_class = ".update_estimate_status_form_" + $(this).attr("data-estimate-status");
    $(modal_class).modal("show");
  });

  $(document).on("click", ".new_content_list_btn", function () {
    $(".new_content_list_form").modal("show");
  });
};

$(document).on("click", ".sendMailBtn", function () {
  $(".loaderContentText").text("メールを送信しています。送信件数が多いため完了までこのままお待ち下さい。");
  $(".loaderParent").show();
});

$(document).on("click", ".drop_down_trigger", function () {
  $(this).parent(".drop_down_parent").find(".drop_down_target").slideToggle();
});

// スペース除去
$(document).on("change", 'input[type="text"]', function () {
  const str = $(this).val().trim();
  $(this).val(str);
});

$(document).on("change", ".upload_file_input", function () {
  var text = "";
  for (const file of $(this).prop("files")) {
    if (double_space_check(file?.name)) {
      $(this).val("");
    }
  }
  if ($(this).prop("files").length >= 2) {
    text = $(this).prop("files").length + "ファイル";
  } else {
    text = $(this).prop("files")[0]?.name ?? "ドラッグアンドドロップするか、クリックしてファイルを選択してください";
  }
  $(this).next(".upload_file_label").text(text);
});

$(document).on("click", ".indicater_parent .toggleBtn", function (e) {
  const icon = $(".indicater_parent .icon");
  if (icon.hasClass("up")) {
    icon.removeClass("up");
    icon.addClass("down");
  } else {
    icon.removeClass("down");
    icon.addClass("up");
  }
  $(".indicater_parent .bottom").slideToggle();
});

$(document).on("click", "#menu-btn", function (e) {
  if ($("body").hasClass("active")) {
    $("body").removeClass("active");
  } else {
    $("body").addClass("active");
  }
});

$(document).on("click", ".global_navi .header", function (e) {
  $(this).toggleClass("open");
  $(this).next().slideToggle();
});

$(document).on("click", ".copy_btn", function () {
  if (navigator.clipboard) {
    const text = $(this).prev().text();
    navigator.clipboard.writeText(text);
    const tooltip = '<span id="VV5G9TYnWJrvhHamaYfb" class="tooltip fz12px fwb"><i class="thumbs up icon"></i>コピーOK</span>';
    $(this).parent().append(tooltip);
    setTimeout(function () {
      $("#VV5G9TYnWJrvhHamaYfb").remove();
    }, 1000);
  } else {
    alert("ブラウザもしくは端末がコピーに対応していません");
  }
});

$(document).on("click", ".video_url_copy_btn", function () {
  if (navigator.clipboard) {
    const text = $(this).attr("data-clipboard-text");
    navigator.clipboard.writeText(text);
    const tooltip = '<span id="zwhmGaNLMtZX47r9uCPR" class="tooltip fz12px fwb"><i class="thumbs up icon"></i>コピーOK</span>';
    $(this).parent().parent().append(tooltip);
    setTimeout(function () {
      $("#zwhmGaNLMtZX47r9uCPR").remove();
    }, 1000);
  } else {
    alert("ブラウザもしくは端末がコピーに対応していません");
  }
});
$(document).on("change", ".five_steps_marketplace_select", function () {
  $(".five_steps_needs_select").dropdown({ values: [] }); //このタイミングで子要素の選択肢を一度リセットしないと何故かセレクトボックスの挙動がおかしくなる
  $(".five_steps_detailed_needs_select").dropdown({ values: [] }); //このタイミングで子要素の選択肢を一度リセットしないと何故かセレクトボックスの挙動がおかしくなる
  let five_steps_marketplace_value = $(this).dropdown("get value");
  if (five_steps_marketplace_value) {
    let array = JSON.parse($(".five_steps_needs_select").attr("data-array"));
    let values = [];
    array[five_steps_marketplace_value].forEach(function (element) {
      values.push({ name: element[0], value: element[1] });
    });
    $(".five_steps_needs_select").dropdown({ values: values });
  }
});

$(document).on("change", ".five_steps_needs_select", function () {
  $(".five_steps_detailed_needs_select").dropdown({ values: [] }); //このタイミングで子要素の選択肢を一度リセットしないと何故かセレクトボックスの挙動がおかしくなる
  let five_steps_needs_value = $(this).dropdown("get value");
  if (five_steps_needs_value) {
    let five_steps_marketplace_value = $(".five_steps_marketplace_select").dropdown("get value");
    let array = JSON.parse($(".five_steps_detailed_needs_select").attr("data-array"));
    let five_steps_detailed_needs_array = array[five_steps_marketplace_value][five_steps_needs_value];
    let values = [];
    five_steps_detailed_needs_array.forEach(function (element) {
      values.push({ name: element[0], value: element[1] });
    });
    $(".five_steps_detailed_needs_select").dropdown({ values: values });
  }
});

$(document).on("click", ".update_project_id_btn", function () {
  $(".ui.modal.update_project_id_form").modal("show");
});

$(document).on("click", ".partner_role_set_btn", function () {
  $(".ui.modal.partner_role_set_form").modal("show");
});

$(document).on("click", ".invoice_info_modal_btn", function () {
  $(".ui.modal.invoice_info_modal").modal("show");
});

$(document).on("click", ".upload_receipt_file_btn", function () {
  $(".ui.modal.upload_receipt_file").modal("show");
});

$(document).on("click", ".sender_modal_btn", function () {
  $(".ui.modal.sender_modal").modal("show");
});

$(document).on("click", ".approver_modal_btn", function () {
  $(".ui.modal.approver_modal").modal("show");
});

$(document).on("click", ".reading_change_history_btn", function () {
  $(".ui.modal.reading_change_history_modal").modal("show");
});

$(document).on("click", ".modal_show_btn", function () {
  const modal_class = ".modal_" + $(this).attr("data-modalid");
  $(modal_class)
    .modal("setting", {
      autofocus: false,
    })
    .modal("show");
});

$(document).on("click", ".partner_contract_histories_modal_btn", function () {
  const modal_class = ".partner_contract_histories_modal_" + $(this).attr("data-contract-document-id");
  $(modal_class).modal("show");
});

$(document).on("click", ".update_reading_status_btn", function () {
  $(".update_reading_status_modal").modal("show");
});

$(document).on("click", ".progress .close", function () {
  $(this).parent(".progress").remove();
});

$(document).on("change", ".accident_level_select", function () {
  set_accident_information_select($(this).parents(".form"));
});

$(document).on("click", ".estimate_tab", function () {
  const cls = $(this).attr("data-tab");
  $(".ui.tab").removeClass("active");
  $("." + cls).addClass("active");
  $(".ui.menu .item").removeClass("active");
  $(this).addClass("active");
});

$(document).on("click", ".reading_status_popup_btn", function () {
  if ($(".reading_status_popup_body").hasClass("active")) {
    $(".reading_status_popup_body").removeClass("active");
  } else {
    $(".reading_status_popup_body").addClass("active");
  }
});

$(document).on("click", ".toggleCostEstimateItemDetail", function () {
  $(".costEstimateItemDetailContent").toggle();
  $(this).toggleClass("on");
});

$(document).on("click", ".flash_message", function (e) {
  $(this).hide();
});

$(function () {
  if (window.innerWidth < 1080) {
    $("body").removeClass("active");
  }
  window.lightbox.option({
    resizeDuration: 0,
    fadeDuration: 0,
    imageFadeDuration: 0,
    fitImagesInViewport: true,
  });
  if (!$("body").hasClass("action_show") && (!$("body").hasClass("controller_contents") || !$("body").hasClass("controller_projects") || !$("body").hasClass("controller_clients") || !$("body").hasClass("controller_meetings"))) {
    callFunctions();
  }
});
